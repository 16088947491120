import { typesFileReports } from '@/config/common'

class ConstructorFiles {
  constructor (props) {
    this.data = {
      commandType: props?.commandType || 'exporting',
      params: {
        config: props?.config || []
      }
    }
  }

  objectsConfig (props) {
    return {
      type: props.type,
      options: {
        query: {
          ids: props.ids,
          eav_entity_id: props.eav_entity_id
        }
      }
    }
  }

  baseConfig (props) {
    return {
      type: props.type,
      options: {
        query: {
          id: props.id
        }
      }
    }
  }

  getQuery (props) {
    return {
      query: {
        passport_id: props.passport_id,
        eav_entity_id: props.eav_entity_id
      }
    }
  }

  geoConfig (props) {
    return {
      object: {
        type: typesFileReports.geo.object,
        options: {
          ...this.getQuery(props)
        }
      },
      passport: {
        ...this.baseConfig({ type: typesFileReports.geo.passport, id: props.id })
      }
    }
  }

  configExcel (props) {
    return {
      object: {
        type: props.type,
        options: {
          fields: {
            [props.key]: {}
          },
          ...this.getQuery(props)
        }
      },
      passport: {
        type: props.type,
        options: {
          fields: {
            [props.key]: {}
          },
          ...this.getQuery(props)
        }
      }
    }
  }
}

export default ConstructorFiles