<template>
  <BaseReport
    :report-label="reportName"
    :params-to-upload="config"
    :value="[value.properties.id]"
    command-type="reporting"
  />
</template>

<script>
import BaseReport from '@/components/base/BaseReportPanel/BaseReport'
import ConstructorFiles from '@/components/structures/constructorFiles'

export default {
  name: 'CommonReport',
  components: { BaseReport },
  props: {
    value: Object,
    actionName: String,
    reportName: String
  },
  computed: {
    config () {
      const commonConfig = new ConstructorFiles().baseConfig({
        type: this.actionName,
        id: this.value.properties.id
      })
      return {
        commandType: 'reporting',
        params: {
          config: [commonConfig]
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
