export default {
  data () {
    return {
      isDialogFilter: false,
      changesFields: {},
      isEmptyFilter: false // для надписи "Нет атр. для фильтрации"
    }
  },
  methods: {
    handleFilter () {
      this.isDialogFilter = !this.isDialogFilter
    },
    handleClear () {
      for (let key in this.changesFields) {
        this.$delete(this.filter, key)
      }
      this.$set(this, 'changesFields', {})
      this.clearSelectedItems()
      this.isDialogFilter = !this.isDialogFilter
    },
    handleSearch () {
      this.$set(this, 'filter', { ...this.filter, ...this.changesFields })
      this.clearSelectedItems()
      this.isDialogFilter = !this.isDialogFilter
    },
    changeField(data) {
      this.$set(this, 'changesFields', data)
    },
    emptyField(data) {
      this.$set(this, 'isEmptyFilter', data)
    },
    clearSelectedItems () {
      if (this.selectedItems && this.selectedItems.length) {
        this.selectedItems = []
      }
    }
  }
}
