import _ from 'lodash'

export default {
  data () {
    return {
      isCheckAll: false,
      checkItems: {}, // чекбоксы
      report: {}
    }
  },
  computed: {
    isCanDownload () {
      return _.values(this.checkItems).find((item) => item)
    }
  },
  methods: {
    checkAll (currentSendingObj, checkboxItems) { // Меняет чекбокс, при изменении items. Это нужно если: были выделены все, но убрали один чекбокс; выделили вручную все
      const keysObject = Array.isArray(currentSendingObj) ? currentSendingObj.sort() : _.keys(currentSendingObj).sort()
      this.isCheckAll = checkboxItems.length === keysObject.length && _.isEqual(keysObject, _.keys(this.checkItems).sort())
    }
  }
}