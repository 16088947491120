<template>
  <BaseCard
    :is-small-height="$route.name === 'map'"
    without-class-content
    :class="$route.name === 'passportObjectList' || $route.name === 'map' ? 'passport-reports_in_dialog' : 'passport-reports'"
  >
    <template
      v-if="type === 'passport' && $route.name !== 'map'"
      #header
    >
      <BaseHeader
        :close-btn="false"
        :save-btn="false"
        :delete-btn="false"
      />
    </template>
    <template #content>
      <v-tabs
        active-class="active-tabs"
        class="tabs"
        v-model="tab"
        :show-arrows="type === 'passport'"
      >
        <v-tab
          style="width: 230px"
          class="tabs"
          v-for="item in passportReports"
          v-if="item.types.includes(type)"
          :key="item.reportLabel"
          :class="type === 'object' ? 'mb-2' : ''"
        >{{ item.reportLabel }}
        </v-tab>

        <v-tab-item
          v-for="item in passportReports"
          :key="item.reportLabel"
          v-if="item.types.includes(type)"
          style="overflow-y: hidden"
        >
          <component
            :is="item.component"
            :action-name="item.actionName"
            :report-name="item.reportLabel"
            :ids="value.properties.id"
            :value="value"
            :type="type"
            :type-config="item.typeConfig"
            :eav-entity="eavEntity"
            :selected-items="selectedItems"
            :is-fixed-btn="isFixedBtn || item.reportLabel !== 'GEOJSON'"
          />
        </v-tab-item>
      </v-tabs>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/base/BaseCard'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import CommonReport from '@/components/views/account/passport/detail/views/passportReports/CommonReport'
import ReportExcel from '@/components/views/account/passport/detail/views/passportReports/ReportExcel'
import ReportGeoJson from '@/components/views/account/passport/detail/views/passportReports/ReportGeoJson'
import { typesFileReports } from '@/config/common'

export default {
  name: 'PassportReports',
  components: {
    BaseHeader,
    BaseCard,
    CommonReport,
    ReportExcel,
    ReportGeoJson
  },
  props: {
    value: Object,
    type: {
      default: 'passport',
      type: String
    },
    eavEntity: Object,
    selectedItems: Array,
    isFixedBtn: Boolean
  },
  data () {
    return {
      dialog: false,
      tab: null
    }
  },
  computed: {
    passportReports () {
      return [
        {
          actionName: typesFileReports.svodka,
          reportLabel: 'Паспорт территории',
          types: 'passport',
          component: 'CommonReport',
          typeConfig: ''
        },
        {
          actionName: '',
          reportLabel: 'Ведомость Файл XLS',
          types: ['object', 'passport'], // для определения где использовать
          component: 'ReportExcel',
          typeConfig: 'object' //для конфига отправки на серв
        },
        {
          actionName: '',
          reportLabel: 'GEOJSON',
          types: ['object', 'passport'],
          component: 'ReportGeoJson',
          typeConfig: 'object'
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
  .passport-reports, .passport-reports_in_dialog {
    height: calc(100vh - 17px);
  }
  @media (max-height: 450px) {
    .passport-reports_in_dialog {
      height: 58vh;
    }
  }
  .active-tabs {
    color: #009EDB;
  }
  .tabs {
    background-color: #F5F5F5;
    margin-right: 15px;
  }
  /deep/ .v-slide-group__content .v-tabs-bar__content {
    gap: 15px !important;
  }
  .items-tabs {
    min-height: 40vh;
    max-height: calc(90vh - 170px);
    overflow-y: auto;
  }
</style>
