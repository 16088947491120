<template>
    <v-form-base
      v-if="Object.keys(schema).length !== 0"
      :model="model"
      :schema="schema"
      @input="handleInput($event)"
      :key="keyFilterForm"
    />
</template>

<script>
import VFormBase from 'vuetify-form-base'
import BaseCard from '@/components/base/BaseCard'
import BaseAutocomplete from '@/components/base/BaseAutocomplete'
import BaseBtn from '@/components/base/UI/BaseBtn'
import { cleanClone, flatenObject } from '@/components/utils/common'
import filterCommon from '@/components/mixins/filterCommon'
import { filterBaseAttrs } from '@/config/common'

export default {
  name: 'FilterObject',
  components: { BaseBtn, BaseAutocomplete, BaseCard, VFormBase },
  mixins: [filterCommon],
  props: {
    value: Object
  },
  data () {
    return {
      schema: {
        withFiles: {
          "type": "checkbox",
          "min": null,
          "max": null,
          "dataType": "string",
          "label": "С документами",
          "name": "withFiles",
          "color": "primary",
          "isMultiple": false,
          "categoryId": null,
          "catId": null,
          "prependInnerIcon": "",
          "appendIcon": null,
          "dense": true,
          "outlined": true,
          "clearable": true,
          "emptyValue": false,
          "col": {
            "cols": 10
          }
        }
      },
      detail: cleanClone(this.value),
      model: {},
      changesField: {}
    }
  },
  computed: {
    attributes () {
      const currentItem = this.detail.properties.passportEntities.find(item => item.eavEntity.id === Number(this.$route.params.entityId))
      const filteredBase = currentItem.eavEntity.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      return cleanClone(filteredBase.concat(currentItem.eavEntity.eavAttributes))
    }
  },
  methods: {
    handleInput (data) {
      if (data.data) {
        const changesItem = { [data.key]: data.value }
        this.$set(this, 'changesField', { ...this.changesField, ...changesItem })
        this.$emit('change', this.changesField)
      }
    },
    buildSchema () {
      const types = {
        'text': 'attributeTextField',
        'text_area': 'attributeTextField',
        'number': 'rangeOption',
        'option': 'searchSelect',
        'multipleOption': 'searchSelect',
        'complexOption': 'searchSelect'
      }
      let flatAttributes = flatenObject(this.attributes, 'relatedAttributes').filter((item) => item.eavType.id !== 14 && item.eavType.id !== 9)

      flatAttributes.map(item => {
        if (item.is_filter) {
          const iRulesObject = !!item.attributeRule.rules[1]

          const propsRange = {
            min: iRulesObject ? Number(item.attributeRule.rules[1].min) : NaN,
            max: iRulesObject ? Number(item.attributeRule.rules[1].max) : NaN
          }

          this.model[item.name] = item.defaultValue

          const icon = item.eavType.name === 'option' ? 'mdi-menu-down' : item.eavType.name === 'multipleOption' ||
          item.eavType.name === 'complexOption' ? 'mdi-magnify' : null

          let schemaItem = {
            type: types[item.eavType.name],
            ...propsRange,
            dataType: item.attributeRule.rules[0],
            label: item.label,
            name: item.name,
            color: 'primary',
            isMultiple: item.eavType.name === 'multipleOption',
            categoryId: item.optionCategoryId,
            catId: item.optionCategoryId,
            relatedAttributes: item.relatedAttributes,
            prependInnerIcon: '',
            appendIcon: icon,
            dense: true,
            outlined: true,
            clearable: false,
            emptyValue: item.eavType.name === 'option',
            col: { cols: 12 }
          }
          this.$set(this.schema, item.name, schemaItem)
        }
      })
    }
  },
  mounted () {
    this.buildSchema()
    if (Object.keys(this.schema).length === 0) {
      this.$emit('emptyField', true)
    }
  }
}
</script>
