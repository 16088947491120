<template>
  <div>
    <BaseList
      :passport-entities="value.properties.passportEntities"
      v-model="selectedItems"
      :headers="headers"
      :key="localKey"
      controller="object"
      :data-base-delete-btn="currentRole === 'admin' && !!selectedItems.length"
      :query="{filter}"
      :show-select="true"
      :is-report="!!selectedItems.length"
      :report-with-settings="!!isShowReportWithSettings"
      @report="handleReport"
      @import="handleImport"
      @dataBaseDelete="isDeleteDBDialog = true"
      @filter="handleFilter"
      @reportWithSettings="dialogWithSettings = true"
      :can-create="isCanInteractionObject"
      :is-import="isCanInteractionObject"
      is-filter
      @close="$router.push({ name: 'passport' })"
      item-key="properties.id"
      :all-items="allItems.models"
    >
      <template #item="{item, isSelected}">
        <ListObjectItem
          :is-selected="isSelected"
          selectable
          :item="item"
          @input="handleSelected($event)"
          :headers="headers"
          :verification-scenario="filter.verificationScenario"
        />
      </template>
    </BaseList>
    <BaseDialog
      label="Отчеты"
      is-cross-close
      hide-scroll
      v-model="dialogWithSettings"
      :max-width="800"
    >
      <template #content>
        <PassportReports
          type="object"
          :value="value"
          :eav-entity="eavEntity"
          :selected-items="selectedItems"
          class="mb-5"
        />
      </template>
    </BaseDialog>
    <BaseReportPanel
      v-model="dialog"
      report-type="object"
      :selected-items="selectedItems"
    />
    <!--  старая иморт панель  <PassportImportPanel
          v-model="importDialog"
          :passport-entity="objectAttributes"
          is-object
          @parse:completed="handleParseComplete"
        />-->
    <BaseDialog
      v-model="importDialog"
      is-cross-close
      label="Менеджер импорта"
      without-sides-padding
      little-label
      without-bot-padding
    >
      <template #subtitle>
        <div class="text-h4 font-weight-bold">{{ eavEntity.eavEntity.entityName }}</div>
      </template>
      <template #content>
        <BaseImportPanel
          :file-type="fileType"
          :next-step-start-after="nextStepStartAfter"
          :previous-step-end-after="previousStepEndAfter"
          :params-to-upload="paramsToUpload"
          :passport-entity="objectAttributes"
          @parse:completed="handleParseComplete"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      label="Фильтр"
      v-model="isDialogFilter"
      :max-width="800"
      hideScroll
      is-cross-close
      @confirm="handleSearch"
    >
      <template #content>
        <BaseFilter
          :is-empty-filter="isEmptyFilter"
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
        >
          <template #filters>
            <FilterObject
              @change="changeField"
              @emptyField="emptyField"
              :value="value"
            />
          </template>
        </BaseFilter>
      </template>
    </BaseDialog>
    <BaseDialog
      :max-width="400"
      v-model="isLoading"
      v-if="isLoading"
    >
      <template #content>
        <BasePreloader
          size="40"
          v-if="isLoading"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      is-cross-close
      :max-width="400"
      label="Вы уверены что хотите удалить выбранные объекты?"
      v-model="isDeleteDBDialog"
      is-confirm-btn
      is-cancel-btn
      v-if="isDeleteDBDialog"
      @confirm="hardDelete"
    >
    </BaseDialog>
  </div>
</template>

<script>
import BaseList from '@/components/base/BaseList'
import ListObjectItem
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectsTable/ListObjectItem'
import passportObjectCommon from '@/components/mixins/passportObjectCommon'
import BaseReportPanel from '@/components/base/BaseReportPanel'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import report from '@/components/mixins/report'
import importMixin from '@/components/mixins/import'
import PassportImportPanel from '@/components/views/account/passport/common/PassportImportPanel'
import { flatenObject, sortWithNull } from '@/components/utils/common'
import FilterObject
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectsTable/FilterObject'
import BaseDialog from '@/components/base/BaseDialog'
import BaseFilter from '@/components/base/BaseFilter'
import filter from '@/components/mixins/filter'
import userInteraction from '@/components/mixins/userInteraction'
import PassportReports from '@/components/views/account/passport/detail/views/PassportReports'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'
import BasePreloader from '@/components/base/UI/BasePreloader'

const PARSING_TYPE = 'object'
const COMMAND_TYPE = 'parsing'

export default {
  mixins: [passportObjectCommon, tableSelectedItems, report, importMixin, filter, userInteraction],
  name: 'PassportObjectsTable',
  components: {
    PassportReports,
    BaseDialog,
    FilterObject,
    PassportImportPanel,
    BaseReportPanel,
    ListObjectItem,
    BaseList,
    BaseFilter,
    BaseImportPanel,
    BasePreloader
  },
  props: {
    value: Object,
    scenario: String
  },
  data () {
    return {
      filter: {
        passport_id: this.$route.params.id,
        eav_entity_id: Number(this.$route.params.entityId),
        verificationScenario: this.scenario,
        ...this.$route.query.filter
      },
      dialogWithSettings: false,
      localKey: 0,
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          passportId: this.$route.params.id ? this.$route.params.id : null,
          eavEntityId: this.$route.params.entityId ? this.$route.params.entityId * 1 : null,
          parsingType: PARSING_TYPE
        }
      },
      isDeleteDBDialog: false,
      isLoading: false,
      allItems: []
    }
  },
  watch: {
    scenario: {
      handler () {
        this.$set(this.filter, 'verificationScenario', this.scenario)
        this.fillAllItems()
      }
    }
  },
  computed: {
    isShowReportWithSettings () {
      return this.eavEntity.statistics.commitCount + this.eavEntity.statistics.diffCount + this.eavEntity.statistics.originCount
    },
    objectAttributesFlat () {
      return this.eavEntity.eavEntity.baseAttributes.concat(flatenObject(this.eavEntity.eavEntity.eavAttributes, 'relatedAttributes'))
    },
    headers () {
      if (this.value && Object.keys(this.value).length) {
        return this.objectAttributesFlat
          .filter(item => item.show_in_table)
          .sort(sortWithNull('order'))
          .map(item => ({
            text: item.label,
            value: item.name,
            optionCategoryId: item.optionCategoryId,
            type: item.eavType.name,
            align: 'start',
            sortable: false,
            showTooltip: !!this.getLabelGroup(item),
            tooltipText: item.parent_id && this.getLabelGroup(item)
          }))
          .filter(item => item.type !== 'complexOption')
      }
    },
    getCurrentRole () {
      return this.$store.getters['auth/getCurrentRole']
    }
  },
  methods: {
    getLabelGroup (item) {
      let result = ''
      const parentItem = this.objectAttributesFlat.find((element) => element.id === item.parent_id)
      if (parentItem?.eavType.id === 14) {
        result = 'Группа: ' + parentItem.label + '. \r\n \n ' + 'Атрибут: ' + item.label
      }
      return result
    },
    handleParseComplete () {
      this.localKey++
      this.importDialog = false
    },
    async deleteFromMap () {
      this.$store.commit('map/deleteObjectsTable', this.value.properties.id)
    },
    async hardDelete () {
      this.isLoading = true
      this.isDeleteDBDialog = false
      await this.deleteFromMap()
      const ids = this.selectedItems.map((item) => item.properties.id).join(',')
      await this.$store.dispatch('server/delete', {
        url: 'object/detail',
        data: {
          hard: 1,
          id: ids
        }
      }).then(() => {
        this.localKey += 1
      })
      this.selectedItems = []
      this.isLoading = false
      return true
    },
    async fillAllItems () {
      if (!this.isLoading) {
        this.$emit('loading', true)
        this.isLoading = true
        this.allItems = await this.$store.dispatch('server/get', {
          url: '/object',
          params: {
            options: {},
            filter: {
              passport_id: this.$route.params.id,
              eav_entity_id: Number(this.$route.params.entityId),
              verificationScenario: this.scenario,
            }
          }
        }) || { models: [], count: 0 }
        this.isLoading = false
        this.$emit('loading', false)
      }
      return true
    }
  },
  mounted () {
    if (this.filter.verificationScenario !== this.scenario) {
      this.$set(this.filter, 'verificationScenario', this.scenario)
    }
    this.fillAllItems()
  }
}
</script>

<style scoped>

</style>
