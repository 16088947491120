export default {
  props: {
    value: Boolean
  },
  data() {
    return{
      params: null
    }
  },
  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
  },
  watch: {
    value () {
      return this.$nextTick(() => {
        if (this.value) {
          this.$refs.detail.check()
        } else {
          this.$refs.uploader.stopUploading()
        }
      })
    }
  },
  methods: {
    handleComplete () {
      this.$refs.detail.check()
    },
    handleStart (data) {
      this.params.params.fileName = data.fileName
      this.params.params.crs = data.crs
      this.$refs.detail.start()
    }
  }
}
