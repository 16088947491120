<template>
  <v-alert
    :color="color"
    dense
    :icon="icon"
    :type="type"
    class="ma-0"
    :outlined="outlined"
    :colored-border="coloredBorder"
    :max-width="maxWidth"
    :max-height="maxHeight"
  >
    {{text}}
  </v-alert>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    text: String,
    color: String,
    icon: String,
    type: String,
    outlined: Boolean,
    coloredBorder: Boolean,
    maxWidth: String,
    maxHeight: String
  }
}
</script>

<style scoped>

</style>
