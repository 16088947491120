<template>
  <v-btn
    :disabled="disabled"
    :color="color"
    :small="small"
    :text="text"
    :icon="isTypeIcon"
    :x-small="xSmall"
    @click.stop="$emit('click')"
    :loading="loading"
    class="ml-1"
    :to="to"
    :class="classBtn"
    :width="width"
    :depressed="depressed"
    :height="height"
    :large="large"
    :x-large="xLarge"
    :ripple="ripple"
  >
    <v-icon
      v-if="icon"
      :class="title ? 'mr-2' : ''"
      :x-small="xSmallIcon || xSmall"
      :color="colorIcon"
      :small="isSmallIcon"
      :size="size"
    >{{ icon }}
    </v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    icon: String,
    color: String,
    title: String,
    size: String,
    small: Boolean,
    xSmall: Boolean,
    isSmallIcon: Boolean,
    xSmallIcon: Boolean,
    disabled: Boolean,
    text: Boolean,
    isTypeIcon: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    to: Object,
    classBtn: String,
    width: String,
    height: String,
    depressed: Boolean,
    large: Boolean,
    xLarge: Boolean,
    colorIcon: String,
    ripple: Boolean
  }
}
</script>

<style scoped>

</style>
