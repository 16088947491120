export default {
  data () {
    return {
      importDialog: false
    }
  },
  methods: {
    handleImport () {
      this.importDialog = !this.importDialog
    }
  }
}
