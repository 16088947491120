<template>
  <BaseDialog
    :value="showDialog"
    @input="handleParseComplete"
    is-cross-close
  >
    <template #content>
      <!-- в <BaseImportUploader было @complete="handleComplete"  -->
      <BaseImportUploader
        ref="uploader"
        v-model="params"
        url="filemanager/file/upload"
        @uploaded="handleStart"
        file-type="geojson"
        :is-hide-manual-change="isHideManualChange"
      />
      <v-tabs v-if="params">
        <v-tab>Импортировалось с ошибками</v-tab>
        <v-tab>Таблица</v-tab>
        <v-tab>Пример</v-tab>
        <v-tab-item eager>
          <!--  @parse:completed="handleParseComplete" доделать - обновление списка после парсинга -->
          <BaseParserDetails
            class="mt-1"
            ref="detail"
            :object-type="objectType"
            :params.sync="params"
          />
        </v-tab-item>
        <v-tab-item>
          <BaseGeoJsonExample
            class="mt-1"
            v-if="dataPassport"
            :pre-geo-json="dataPassport"
          />
        </v-tab-item>
        <v-tab-item>
          <div>
            Пример структуры объекта GeoJSON:
            <BaseCodeBlock :code="geoJson"/>
          </div>
        </v-tab-item>
      </v-tabs>
    </template>
  </BaseDialog>
</template>

<script>
import BaseParserDetails from '@/components/base/BaseParserDetails'
import BaseDialog from '@/components/base/BaseDialog'
import BaseImportUploader from '@/components/base/BaseImportUploader'
import importPanel from '@/components/mixins/importPanel'
import BaseGeoJsonExample from '@/components/base/BaseGeoJsonExample'
import BaseCodeBlock from '@/components/base/UI/BaseCodeBlock'
import testDataPassport from '@/components/views/account/passport/common/testDataPassport'
import { filterAttributes, flatenObject } from '@/components/utils/common'

export default {
  mixins: [importPanel],
  name: 'PassportImportPanel',
  components: {
    BaseGeoJsonExample,
    BaseImportUploader,
    BaseDialog,
    BaseParserDetails,
    BaseCodeBlock
  },
  props: {
    passportEntity: Array,
    isObject: {
      default: false,
      type: Boolean
    },
    isHideManualChange: Boolean
  },
  data () {
    return {
      geoJson: {
        features: [{
          type: 'feature',
          geometry: {
            type: '',
            coordinates: []
          },
          properties: {}
        }]
      },
      dataPassport: this.passportEntity
    }
  },
  computed: {
    objectType () {
      return this.params.params.passportId ? 'object' : 'passport'
    }
  },
  methods: {
    createGeoJsonObject () {
      const filteredPassport = flatenObject(filterAttributes(this.dataPassport, ['geometry', 'latitude', 'longitude', 'id', 'state']), 'relatedAttributes')
      return filteredPassport.forEach((item) => {
        if ((item.eavType.id !== 9 && item.eavType.id !== 14)) {
          this.geoJson.features[0].properties[item.name] = item.label
        }
      })
    },
    /*checkPassport () {
      if (this.$route.params.entityId) {
        this.isPassport = true
      }
    },*/
    async setDataPassport () {
      if (!this.passportEntity && !this.isObject) {
        this.dataPassport = testDataPassport
        this.createGeoJsonObject()
      } else {
        this.createGeoJsonObject()
      }
    },
    handleParseComplete () {
      this.$emit('parse:completed')
    }
  },
  mounted () {
    //this.checkPassport()
    this.setDataPassport()
    this.params = {
      commandType: 'parsing',
      params: {
        passportId: this.$route.params.id ? this.$route.params.id : null,
        eavEntityId: this.$route.params.entityId ? this.$route.params.entityId * 1 : null
      }
    }
    this.params.params.objectType = this.objectType
  }
}
</script>

<style scoped>

</style>
