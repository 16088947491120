<template>
  <div>
    <!--todo: Вика - сделать элемент адаптируемым под размеры контейнера. Попробовать избавиться от v-if в v-card-->
    <v-card
      outlined
      elevation="1"
      class="my-6 px-2 mx-2"
    >
      <v-card-title class="pa-1 d-flex subtitle-1 align-center">
        <v-icon
          class="mr-4"
          color="green"
        >mdi-tree
        </v-icon>
        {{ reportLabel }}
        <v-card-actions class="ml-auto">
          <v-btn
            small
            color="primary"
            @click="start"
            active-class="active"
            :loading="isChecking"
          >
            <v-icon
              small
              dense
              class="mr-2"
            >mdi-download
            </v-icon>
            Скачать
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <v-card-text
        @click="show = !show"
        class="pb-0"
      >
        Описание отчета
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-card-text>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <p>Количество {{ value.length }}
            <p/>
          </v-card-text>
          <v-card-text
            class="pb-0"
            v-if="status"
          >
            <p>Статус: <span :class="status.color+'--text'">{{ status.label }}</span></p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import process from '@/components/mixins/process'

export default {
  name: 'BaseReport',
  mixins: [process],
  props: {
    value: Array,
    actionName: String,
    reportLabel: String,
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
