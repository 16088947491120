<template>
  <v-card
    flat
    :class="isAdditionalStyles ? 'pa-5 pt-2' : 'pb-2'"
  >
    <div
      v-if="!isEmptyFilter"
      style="max-height: calc(100vh/2)"
      :class="isAdditionalStyles ? 'overflow-y-auto pb-3 pr-4' : ''"
    >
      <slot name="filters"></slot>
    </div>

    <div
      v-if="!isEmptyFilter"
      :class="isAdditionalStyles ? 'mt-5' : 'mt-2'"
      class="d-flex justify-end"
    >
      <BaseBtn
        title="Найти"
        color="primary"
        :class="isAdditionalStyles ? '' : 'mr-2'"
        @click="handleConfirm"
        @keyup.enter="handleConfirm"
        :loading="isLoading"
        :small="$route.meta.layout === 'MobileLayout'"
      />
      <v-btn
        :color="isAdditionalStyles ? 'primary' : 'white'"
        :class="isAdditionalStyles ? '' : 'primary--text'"
        :plain="isAdditionalStyles"
        @click="clearFilter"
        :loading="isLoading"
        :small="$route.meta.layout === 'MobileLayout'"
      >
        Очистить
      </v-btn>
    </div>

    <div
      v-if="isEmptyFilter"
      class="d-flex justify-center primary--text"
    >
      Нет атрибутов для фильтрации
    </div>
  </v-card>
</template>

<script>

import BaseBtn from '@/components/base/UI/BaseBtn'
import keyup from '@/components/mixins/keyup'

export default {
  name: 'BaseFilter',
  components: { BaseBtn },
  mixins: [keyup],
  props: {
    isEmptyFilter: Boolean,
    isAdditionalStyles: {
      default: true,
      type: Boolean
    },
    isLoading: Boolean,
    value: Boolean
  },
  methods: {
    handleConfirm () {
      return this.$emit('handleSearch')
    },
    clearFilter () {
      this.$emit('clearFilter')
    }
  }
}
</script>