<template>
  <div>
    <v-card-subtitle v-if="entityName && countObject">
      Количество объектов "{{ entityName }}" – {{ countObject }}
    </v-card-subtitle>
    <BaseReportExcel
      @checkAll="onChangeCheckboxAll"
      :is-check-all="isCheckAll"
      :is-can-download="isCanDownload"
      :report="report"
      :text-alert="$t('message.choiceAttr')"
      :max-width-alert-download="type === 'object' ? '440' : ''"
    >
      <template #col-left-object>
        <v-col v-if="type === 'passport'">
          <v-list>
            <v-list-item
              v-for="item in value.properties.passportEntities"
              :key="item.id"
              class="item-object mb-1"
              @click="onClickListItem(item)"
              :class="item.id === currentActiveObject.id && 'active-item-object'"
            >
              <v-icon
                class="mr-2"
                :color="item.eavEntity.style.color"
              >{{ item.eavEntity.icon }}
              </v-icon>
              <v-list-item-subtitle>{{ item.eavEntity.entityName }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>
      </template>
      <template #listCheckboxes>
        <v-checkbox
          class="pa-0 mb-4"
          dense
          hide-details
          v-for="item in attributesByCurrentActiveObject"
          :key="item.id"
          :label="item.label"
          v-model="checkItems[item.name]"
        />
      </template>
    </BaseReportExcel>
  </div>

</template>

<script>
import BaseReportExcel from '@/components/base/BaseReportExcel'
import { cleanClone, filterAttributes, flatenObject } from '@/components/utils/common'
import reportCommon from '@/components/views/account/passport/detail/views/passportReports/reportCommon'
import { filterBaseAttrs, typesFileReports } from '@/config/common'
import ConstructorFiles from '@/components/structures/constructorFiles'

export default {
  name: 'ReportExcel',
  components: { BaseReportExcel },
  mixins: [reportCommon],
  props: {
    type: String,
    value: Object,
    eavEntity: Object,
    selectedItems: Array, // объекты в таблице
    typeConfig: {
      type: String
    }
  },
  data () {
    return {
      currentActiveObject: this.type === 'passport' ? this.value?.properties?.passportEntities[0] : this.eavEntity,
      report: {
        ...new ConstructorFiles({ commandType: 'reporting' }).data
      }
    }
  },
  watch: {
    checkItems: {
      handler () {
        for (let key in this.checkItems) {
          if (this.indexCurrentConfig === -1 && this.checkItems[key] === true) {
            const config = new ConstructorFiles().configExcel({
              type: typesFileReports.xls[this.typeConfig], //object
              key,
              passport_id: this.value?.properties.id,
              eav_entity_id: this.currentActiveObject.eavEntity.id
            })
            this.report.params.config.push(config[this.type])
            this.addConfigIds()
          } else {
            this.checkItems[key] === true ?
              this.report.params.config[this.indexCurrentConfig].options.fields[key] = {} :
              delete this.report.params.config[this.indexCurrentConfig].options.fields [key]
          }
        }
        this.checkAll(this.report.params.config[this.indexCurrentConfig]?.options?.fields, this.attributesByCurrentActiveObject)
      },
      deep: true
    },
    selectedItems: {
      handler () {
        this.addConfigIds()
      },
      deep: true,
      immediate: true
    },
    'currentActiveObject.eavEntity.id': {
      handler () {
        let currentId = this.currentActiveObject.eavEntity.id
        let foundQuery = _.find(this.report.params.config, ['options.query.eav_entity_id', currentId])
        if (foundQuery) {
          const fields = foundQuery.options.fields

          // Проверим, существует ли свойство fields и является ли объектом
          if (fields && typeof fields === 'object') {
            const newCheckItems = {}

            // Получим имена свойств объекта fields
            const fieldNames = Object.keys(fields)

            // Пройдемся по каждому имени свойства и установим значение true
            fieldNames.forEach(fieldName => {
              newCheckItems[fieldName] = true
            })

            this.checkItems = newCheckItems

          }
        }
      }
    }
  },
  computed: {
    attributesByCurrentActiveObject () {
      const filteredBase = this.currentActiveObject.eavEntity.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      let attrs = cleanClone(filteredBase.concat(this.currentActiveObject.eavEntity.eavAttributes))
      attrs = filterAttributes(attrs, ['id', 'geometry', 'state'])
      attrs = flatenObject(attrs, 'relatedAttributes')
      return attrs.filter(attr => attr.eavType.id !== 9 && attr.eavType.id !== 14)
    },
    indexCurrentConfig () {
      return this.report.params.config.findIndex((item) => item.options?.query.eav_entity_id === this.currentActiveObject?.eavEntity.id)
    },
    countObject () {
      let count = ''
      if (this.selectedItems?.length) {
        count = this.selectedItems.length
      } else if (this.eavEntity?.statistics?.originCount) {
        count = this.eavEntity.statistics.originCount
      } else if (this.currentActiveObject?.statistics?.originCount) {
        count = this.currentActiveObject.statistics.originCount
      }
      return count
    },
    entityName () {
      let name = ''
      if (this.eavEntity?.eavEntity?.entityName) {
        name = this.eavEntity.eavEntity.entityName
      } else if (this.currentActiveObject?.eavEntity?.entityName) {
        name = this.currentActiveObject.eavEntity.entityName
      }
      return name
    }
  },
  methods: {
    addConfigIds () { // в текущем объекте(например газон) добавить выбранные в таблице объекты
      const check = this.type === 'object' && this.report.params.config.length
      if (check && this.selectedItems.length) {
        this.report.params.config[0].options.query.ids = this.selectedItems.map(item => item.properties.id)
      } else if (check && !this.selectedItems.length && this.report.params.config[0].options.query.hasOwnProperty('ids')) { //удалить ids, если убраны чекбоксы
        delete this.report.params.config[0].options.query ['ids']
      }
    },
    onChangeCheckboxAll (event) {
      this.attributesByCurrentActiveObject.forEach((item) => {
        this.$set(this.checkItems, item.name, event)
      })
    },
    onClickListItem (item) { //переключение между объектами в паспорте
      this.currentActiveObject = item
      this.checkItems = {} //снять все чекбоксы
      this.isCheckAll = false
      if (this.report.params.config.length) {//это нужно чтобы чекнуть чекбоксы, если мы возвращаемся с другого объекта. В общем, при переключении туда-сюда.
        const findReport = this.report.params.config.find((element) => element.options.eav_entity_id === item.eavEntity.id)
        if (findReport) {
          for (const key in findReport?.fields) {
            this.$set(this.checkItems, key, true) //если в текущем конфиге объекта есть атрибуты объекта, то чекбокс актив на них
          }
          this.checkAll(findReport?.fields, this.attributesByCurrentActiveObject)
        }
      }
    }
  }
}
</script>

<style scoped>
  .item-object {
    background-color: #f4f4f4;
  }
  .active-item-object {
    background-color: #c3e9f8;
  }
</style>