import { checkDateExpired } from '@/components/utils/common'

export default {
  computed: {
    styleItems () {
      const styleItems = {}
      this.headers?.forEach(col => {
        if (col.value === 'srok_ekspluatatsii' && this.item.properties[col.value]) {
          styleItems[col.value] = {}
          styleItems[col.value]['className'] = checkDateExpired(this.item.properties[col.value]) || ' '
        } else {
          styleItems[col.value] = {}
          styleItems[col.value]['className'] = ' '
        }
      })
      return styleItems
    }
  }
}