<template>
  <div>
    <BaseAlert
      v-if="status && !!status.value"
      :type="status.value === 'wait' ? 'info' : status.value"
      :text="textAlert"
      class="mt-2 mb-4 overflow-hidden"
      :max-width="maxWidthAlert"
    />
    <BaseBtn
      small
      color="primary"
      active-class="active"
      icon="mdi-download"
      :title="'Скачать отчет ' + this.typeDownload"
      :loading="isChecking"
      @click="start"
    />
  </div>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseAlert from '@/components/base/UI/BaseAlert'
import process from '@/components/mixins/process'

export default {
  name: 'DownloadReport',
  components: { BaseAlert, BaseBtn },
  mixins: [process],
  props: {
    ids: Number,
    typeDownload: {
      default: '.XLS',
      type: String
    },
    maxWidthAlert: String
  },
  computed: {
    textAlert () {
      let result = this.status.label
      if (this.status && this.status.value === 'error') {
        result = this.status.label + ': ' + this.error
      }
      return result
    }
  },
  methods: {
    canDownload (response) {
      return response.data?.status === 'success'
    }
  }
}
</script>
