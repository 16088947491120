<template>
  <BaseReportExcel
    @checkAll="onChangeCheckboxAll"
    :is-check-all="isCheckAll"
    :is-can-download="isCanDownload"
    class-container="mt-5"
    :report="report"
    :text-alert="type === 'passport' ? $t('message.choiceAttr') : $t('message.typeReportName')"
    :is-show-all="type === 'passport'"
    type-download=".zip"
    :max-width-alert-download="type === 'object' ? '440' : ''"
    command-type="exporting"
    :is-fixed-btn="isFixedBtn"
  >
    <template
      #listCheckboxes
      v-if="type === 'passport'"
    >
      <v-checkbox
        class="pa-0 mb-4"
        dense
        hide-details
        v-for="item in value.properties.passportEntities"
        :key="item.eavEntity.id"
        :label="item.eavEntity.entityName"
        v-model="checkItems[item.eavEntity.id]"
        :disabled="!isHaveObjects(item.statistics)"
      />
      <v-divider class="black"></v-divider>
      <v-checkbox
        class="pa-0 mb-4"
        dense
        hide-details
        :label="$t('passport.reportByPassport')"
        v-model="isReportPassport"
        v-if="type === 'passport'"
      />
    </template>
    <template
      #listCheckboxes
      v-else-if="type === 'object'"
    >
      <p>GEOJSON объектов "{{ eavEntity.eavEntity.entityName }}"</p>
    </template>
  </BaseReportExcel>
</template>

<script>
import reportCommon from '@/components/views/account/passport/detail/views/passportReports/reportCommon'
import BaseReportExcel from '@/components/base/BaseReportExcel'
import _ from 'lodash'
import ConstructorFiles from '@/components/structures/constructorFiles'

export default {
  name: 'ReportGeoJson',
  components: { BaseReportExcel },
  mixins: [reportCommon],
  props: {
    type: String,
    value: Object,
    eavEntity: Object,
    isFixedBtn: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isReportPassport: false, //чекбокс "отчет по паспорту",
      report: { ...new ConstructorFiles({ commandType: 'exporting' }).data }
    }
  },
  watch: {
    checkItems: {
      handler () {
        const currentConfig = this.report.params.config
        for (let key in this.checkItems) {
          if (this.type === 'passport') {
            const findReportId = currentConfig.findIndex(item => +item.options.query.eav_entity_id === +key)
            if (findReportId < 0 && this.checkItems[key]) {
              const geoConfig = new ConstructorFiles().geoConfig({
                passport_id: this.value.properties.id,
                eav_entity_id: key
              }).object
              currentConfig.push(geoConfig)
            } else if (!this.checkItems[key] && findReportId >= 0) {
              currentConfig.splice(findReportId, 1)
            }
          }
        }
        const idsOptions = currentConfig.map((item) => item.options.query.eav_entity_id)
        this.type === 'passport' && this.checkAll(idsOptions, this.filteredEntities)
      },
      deep: true
    },
    isReportPassport: {
      handler () {
        const indexPassportConfig = this.report.params.config.findIndex((item) => item.type === 'geoJsonFilePassport')
        if (this.isReportPassport && this.type === 'passport' && indexPassportConfig < 0) {
          const geoConfig = new ConstructorFiles().geoConfig({ id: this.value.properties.id }).passport
          this.report.params.config.push(geoConfig)
        } else if (!this.isReportPassport && this.type === 'passport' && indexPassportConfig >= 0) {
          this.report.params.config.splice(indexPassportConfig, 1)
        }
      },
      deep: true
    }
  },
  methods: {
    onChangeCheckboxAll (event) {
      this.filteredEntities.forEach((item) => {
        this.$set(this.checkItems, item.eavEntity.id, event)
      })
    },
    isHaveObjects (statistics) { //есть ли объекты в данном типе объекта
      return statistics?.commitCount + statistics?.diffCount + statistics?.originCount
    }
  },
  computed: {
    isCanDownload () {
      return this.type === 'object' || _.values(this.checkItems).find((item) => item) || this.isReportPassport
    },
    filteredEntities () { //фильтр пустых объектов
      return this.value.properties.passportEntities.filter((item) => this.isHaveObjects(item.statistics))
    }
  },
  mounted () {
    if (this.type === 'object') {
      const geoConfig = new ConstructorFiles().geoConfig({
        passport_id: this.value.properties.id,
        eav_entity_id: this.eavEntity.eavEntity.id
      }).object
      this.report.params.config.push(geoConfig)
    }
  }
}
</script>