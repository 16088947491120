export default [
  {
    entityId: 41,
    actionName: 'act-obsledovania',
    reportLabel: 'Акт обследования'
  },
  {
    entityId: 41,
    actionName: 'porubochniy-bilet',
    reportLabel: 'Порубочный билет'
  },
  {
    entityId: 46,
    actionName: 'taksatsionnaya-karta',
    reportLabel: 'Таксационная карта'
  },
  {
    entityId: null, //общий для всех объектов
    actionName: 'object-xls',
    reportLabel: 'Файл xls'
  },
  {
    entityId: 0,
    actionName: 'felling-ticket-list',
    reportLabel: 'Выгрузить список ПБ'
  }
]
