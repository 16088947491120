<template>
  <tr
    :class="classItem"
    @click="detail(item.properties.id)"
  >
    <td
      v-if="selectable"
    >
      <div class="d-flex flex-row">
        <v-checkbox
          class="mt-0 pt-0"
          dense
          v-model="localSelected"
          @click.passive.stop=""
          hide-details
        />
        <v-tooltip
          bottom
          v-if="icon"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="icon"
              :color="icon.color"
            >{{ icon.icon }}
            </v-icon>
          </template>
          {{ icon.tip }}
        </v-tooltip>
      </div>
    </td>
    <td
      v-for="col in headers"
      :key="col.value"
      :class="!!styleItems[col.value] && styleItems[col.value].className ? styleItems[col.value].className : ' '"
    >{{ getFormattedValue(col) }}
    </td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'
import { confirmTypes } from '@/config/common'
import { checkDateExpired, iconObjectStatus } from '@/components/utils/common'
import objectsListItem from '@/components/mixins/passport/objectsListItem'

export default {
  mixins: [formatters, listItem, objectsListItem],
  name: 'ListObjectItem',
  props: {
    headers: Array,
    verificationScenario: String
  },
  computed: {
    icon () {
      const isVerificationPage = this.verificationScenario === 'origin' || this.$route?.query?.filter?.verificationScenario === 'origin' //скрыть иконки для стр
      return !isVerificationPage && iconObjectStatus(this.item.properties.state, this.item.properties.verification_status, this.item.properties?.origin)
    },
    classItem () {
      const isSoftDelete = this.item.properties.state === 2
      const verificationStatus = this.item.properties.verification_status !== 3
      let result = ''
      if (verificationStatus && isSoftDelete) {
        result = 'deleted'
      }
      return result
    }
  },
  methods: {
    getFormattedValue (col) {
      let formattedValue = this.item.properties[col.value]
      if (col.type === 'option') {
        if (col.optionCategoryId) {
          formattedValue = this.$store.getters['handbook/getCategoryValue'](col.optionCategoryId, formattedValue)
        }
      } else if (col.type === 'multipleOption') {
        let valueArray = ''
        if (Array.isArray(formattedValue)) {
          formattedValue.forEach(item => valueArray += this.$store.getters['handbook/getCategoryValue'](col.optionCategoryId, item) + ' ')
        }
        formattedValue = valueArray
      } else if (col.type === 'confirm') {
        formattedValue = confirmTypes.find(item => item.value === formattedValue).label
      }

      return formattedValue
    }
  }
}
</script>

<style scoped>
  .deleted {
    opacity: 0.5;
  }
</style>
