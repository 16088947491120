import { deepEqual } from '@/components/utils/common'

export default {
  data () {
    return {
      keyFilterForm: 1
    }
  },
  watch: {
    '$route.query.filter': {
      handler () {
        const filterQuery = this.$route.query?.filter
        if (filterQuery) {
          for (let key in this.model) {
            if (!deepEqual(filterQuery[key], this.model[key])) {
              this.model[key] = filterQuery[key]
              this.keyFilterForm += 1
            }
          }
        }
      },
      deep: true
    }
  }
}