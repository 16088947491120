import { cleanClone } from '@/components/utils/common'
import { filterBaseAttrs } from '@/config/common'

export default {
  computed: {
    passportDetail () {
      return this.value.properties
    },
    entityId () {
      return this.$route.params.entityId * 1
    },
    objectAttributes () {
      const filteredBase = this.eavEntity.eavEntity.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      return cleanClone(filteredBase.concat(this.eavEntity.eavEntity.eavAttributes))
    },
    eavEntity () {
      return cleanClone(this.passportDetail.passportEntities.find(item => item.eavEntity.id === this.entityId))
    }
  }
}
