<template>
  <v-container
    outlined
    flat
    class="pa-0"
    :class="classContainer"
  >
    <v-row :class=" {'container-checkboxes': true, 'custom-height': $route.name === 'map'}">
      <slot name="col-left-object"/>
      <v-col>
        <v-checkbox
          class="pa-0"
          dense
          v-if="isShowAll"
          hide-details
          label="Все"
          v-model="localCheckAll"
          @change="$emit('checkAll', $event)"
        />
        <v-divider
          v-if="isShowAll"
          class="black"
        ></v-divider>
        <div>
          <slot name="listCheckboxes"/>
        </div>
        <div
          :class="isFixedBtn ? 'fixed-button': ''"
        >
          <DownloadReport
            v-if="isCanDownload"
            :params-to-upload="report"
            :type-download="typeDownload"
            :max-width-alert="maxWidthAlertDownload"
            :command-type="commandType"
          />
          <BaseAlert
            v-else
            type="info"
            class="mt-2 mb-2"
            :text="textAlert"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseAlert from '@/components/base/UI/BaseAlert'
import DownloadReport from '@/components/views/account/passport/detail/views/passportReports/DownloadReport'

export default {
  name: 'BaseReportExcel',
  components: { BaseAlert, DownloadReport },
  props: {
    classContainer: String,
    report: Object,
    isCanDownload: Boolean,
    textAlert: String,
    isCheckAll: Boolean,
    isShowAll: {
      type: Boolean,
      default: true
    },
    typeDownload: String,
    maxWidthAlertDownload: String,
    commandType: {
      default: 'reporting',
      type: String
    },
    isFixedBtn: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      localCheckAll: this.isCheckAll
    }
  },
  watch: {
    isCheckAll: {
      handler () {
        this.localCheckAll = this.isCheckAll
      }
    }
  }
}
</script>
<style>
  .fixed-button {
    position: fixed;
    bottom: 40px;
    padding-bottom: 4px;
  }
  .container-checkboxes {
    width: 95%;
    height: 65vh;
    overflow-y: auto
  }
  .custom-height {
    height: 55vh;
  }
  @media (max-height: 509px) {
    .custom-height {
      height: 45vh;
    }
  }
  @media (max-height: 390px) {
    .custom-height {
      height: 35vh;
    }
  }
</style>