import { typesFileReports } from '@/config/common'

export default [
  {
    actionName: typesFileReports.svodka,
    reportLabel: 'Паспорт территории'
  },
  {
    actionName: typesFileReports.reestr,
    reportLabel: 'Реестр территорий'
  }
]
