<template>
  <v-card
    outlined
    class="ma-2 elevation-0 rounded-lg"
    style="border: none; border-radius: 20px !important;"
    :disabled="disabled"
    :color="color"
    :class="computedClassContent"
  >
    <slot name="header"></slot>
    <v-card-text>
      <slot name="content"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    scrollTop: Boolean,
    isSmallHeight: Boolean,
    disabled: { Boolean, default: false },
    color: String,
    classContent: { type: String, default: '' },
    withoutClassContent: Boolean
  },
  watch: {
    'scrollTop': {
      handler () {
        this.handleScroll()
      }
    }
  },
  methods: {
    handleScroll () {
      let block = document.getElementById('content-block')
      if(block) block.scrollTop = 0
    }
  },
  computed: {
    computedClassContent () {
      let result = this.isSmallHeight ? 'low-content block' : 'content block '
      if (this.classContent) {
        result += this.classContent
      }
      if (this.withoutClassContent) { // пишу этот колхоз чтобы не чистить везде, где этот класс мешает
        let word = this.isSmallHeight ? 'low-content' : 'content'
        // Преобразуем строку в массив слов и удаляем указанное слово
        let resultArray = _.without(result.split(' '), word)

        // Собираем обратно строку из оставшихся слов
        result = resultArray.join(' ')
      }
      return result
    }
  }
}
</script>

<style scoped>
  .block {
    overflow: hidden;
    overflow-y: visible;
    background-color: white;
  }
  .content {
    height: calc(100vh - 75px);
  }
  .low-content {
    height: 65vh;
  }
</style>
