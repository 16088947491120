export default {
  // note важно: в mixins:[] ставить этот миксин самым первым, чтобы функции(handleClose/handleConfirm) из других миксинов переписали текущие, а не наоборот.
  mounted () {
    document.addEventListener('keyup', event => {
      if (event.code === 'Enter' && this.value && !event.shiftKey) this.handleConfirm()
      if (event.code === 'Escape' && this.value) {
        this?.handleClose()
      }
    })
  },
  unmounted () {
    this.removeEventListener()
  },
  methods: {
    handleClose () {}, // во избежание багов здесь эти функции, emit сюда лучше не ставить. В некоторых компонентах это работает некорректно
    handleConfirm () {},
    removeEventListener () {
      document.removeEventListener('keyup', (event) => {
        if (event.code === 'Enter') {
          this.handleConfirm()
        }
        if (event.code === 'Escape') {
          this?.handleClose()
        }
      })
    }
  }
}