export default {
  computed: {
    currentRole: {
      //для локальной роли
      get () {
        return this.$store.getters['auth/getCurrentRole']
      },
      set (val) {
        return this.$store.commit('auth/setCurrentRole', val)
      }
    },
    profile () {
      return this.$store.getters['auth/getProfile']
    }
  }
}
