export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    handleReport () {
      this.dialog = !this.dialog
    }
  }
}
