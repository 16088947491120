<template>
  <div
    :class="size === 'base' ? 'section-base' : ''"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      size="80"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'BasePreloader',
  props: {
    size: {
      type: String,
      default: 'base'
    }
  }
}
</script>

<style scoped>

</style>
