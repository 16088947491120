<template>
  <v-card
    outlined
    min-width="500"
    style="border: none"
  >
    <v-card-text class="pl-1 pt-0">
      <div v-if="fileType === 'geojson'">
        <v-card-title class="pl-0 pt-0 pb-1">
          Текущая проекция: {{ crs || ' —' }}
        </v-card-title>
        <v-radio-group
          class="mt-0"
          label="Способ выбора проекции"
          v-model="currentRadio"
        >
          <v-radio
            value="list"
            label="Выбрать из списка"
          ></v-radio>
          <v-radio
            value="input"
            label="Задать вручную"
          ></v-radio>
        </v-radio-group>
        <div style="width: 70%">
          <v-select
            v-model="crs"
            :items="listOfProjection"
            outlined
            dense
            item-text="projection"
            item-value="value"
            label="Выбрать из списка"
            v-if="currentRadio === 'list'"
          >
          </v-select>
          <v-textarea
            v-if="currentRadio === 'input'"
            dense
            outlined
            v-model="crs"
            placeholder="Введите правку координат"
          >
          </v-textarea>
        </div>
      </div>
      <div class="d-flex">
        <uploader
          v-show="fileType !== 'geojson' || crs"
          ref="uploader"
          :options="uploadConfig"
          class="v-btn mb-4"
          @complete="$emit('complete')"
          @file-added="fileAdded"
        >
          <uploader-unsupport></uploader-unsupport>
          <uploader-btn
            :attrs="attrs"
            class="primary rounded white--text"
          >
            <v-icon
              small
              color="white"
              class="mr-2"
            >mdi-cloud-braces
            </v-icon>
            Загрузить данные <b>.{{ fileType }}</b>
          </uploader-btn>
          <uploader-list></uploader-list>
        </uploader>
        <BaseBtn
          v-if="$route.name === 'map' && fileType === 'geojson' && !isHideManualChange"
          color="primary"
          icon="mdi-draw"
          title="Установить/Изменить вручную"
          small
          is-small-icon
          depressed
          @click="handleMapGeometry"
        />
      </div>
      <div v-if="fileType === 'geojson'">
        <div class="title">Инструкция</div>
        <div class="d-flex">
          <ul>
            <li>Шаг 1 - Введите поправку координат</li>
            <li>Шаг 2 - Загрузите файл GeoJSON</li>
          </ul>
          <strong v-if="$route.name === 'map' && !isHideManualChange"
                  class="pl-3 pt-2">или</strong>
          <ul v-if="$route.name === 'map' && !isHideManualChange">
            <li>Шаг 1 - Нажмите "Установить/Изменить вручную"</li>
            <li>Шаг 2 - Используйте инструменты карты</li>
          </ul>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Config } from '@/config'
import { listOfProjection } from '@/components/utils/projection'
import formatters from '@/components/mixins/formatters'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  name: 'BaseImportUploader',
  components: { BaseBtn },
  mixins: [formatters],
  props: {
    url: {
      type: String,
      default: 'filemanager/file/upload'
    },
    value: Object,
    fileType: String,
    maxFileSize: Number,
    isGeometry: Boolean,
    isHideManualChange: Boolean
  },
  data () {
    return {
      crs: listOfProjection[0].value,
      logId: null,
      attrs: {
        accept: '.' + this.fileType
      },
      currentRadio: 'list',
      listOfProjection
    }
  },
  computed: {
    currentRole () {
      return this.$store.getters['auth/getCurrentRole']
    },
    uploadConfig () {
      let self = this
      return {
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: Config.baseUrl + this.url + '?currentRole=' + this.currentRole,
        testChunks: false,
        chunkSize: 1024 * 1024,
        headers: {
          'Authorization': this.$store.getters['auth/getJwt']
        },
        processResponse (response, cb) {
          if (response) {
            response = JSON.parse(response)
            if (response.xdebug_message) {
              self.$store.dispatch('systemMessages/error', self.exceptionText(response.xdebug_message))
            } else if (response.error) {
              self.$store.dispatch('systemMessages/error', response.error)
            } else if (response.data?.logId) {
              self.$emit('input', { ...self.value, logId: response.data.logId })
            } else if (response.data.status === 'uploaded') {
              self.$emit('uploaded', { fileName: response.data.fileName, crs: self.crs })
            }
          }
          cb(null, response)
        },
        processParams (params) {
          params.crs = self.crs
          for (let key in self.value) {
            if (self.value.hasOwnProperty(key) && key !== 'params') {
              params[key] = self.value[key]
            }
          }
          for (let key in self.value.params) {
            if (self.value.params.hasOwnProperty(key)) {
              params[key] = self.value.params[key]
            }
          }
          return params
        }
      }
    }
  },
  methods: {
    stopUploading () {
      this.$refs.uploader.uploader.cancel()
    },
    exceptionText (xdebug_message) {
      let regex = /Exception:\s*([\p{Script=Cyrillic}\p{P}\s]+)/gu
      let match = regex.exec(xdebug_message)
      return match ? match[1] : ''
    },
    fileAdded (value) {
      let isAllow = false
      if (value) {
        if (value.size > this.maxFileSize) {
          isAllow = false
          this.$store.dispatch(
            'systemMessages/error',
            {
              text: `Размер файла должен быть не больше ${this.bytesToSize(this.maxFileSize, ' ')}`,
              time: 7000
            },
            { root: true }
          )
        }
      } else {
        isAllow = true
      }
      return isAllow
    },
    handleMapGeometry () {
      this.$emit('geometry')
    }
  }
}
</script>

<style scoped>

</style>
