import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  props: {
    value: Array
  },
  data () {
    return {
      selectedItems: []
    }
  },
  watch: {
    value: {
      handler: 'handleValueChange',
      deep: true,
      immediate: true
    }
  },
  computed: {
    localValue () {
      let result = []
      const localValue = cleanClone(this.value)
      cleanClone(this.selectedItems).forEach(item => {
        const index = localValue.findIndex(this.findValueItem(item))
        if (index >= 0) {
          result.push(localValue[index])
        } else {
          result.push(this.formatSelected(item))
        }
      })
      return result
    }
  },
  methods: {
    handleValueChange (newVal, oldVal) {
      if (Array.isArray(this.value) && !deepEqual(newVal, this.selectedItems)) {
        this.selectedItems = this.value.map(this.transformValue)
      }
    },
    handleSelected (data) {
      const index = this.selectedItems.findIndex(this.findSelectedItem(data.item))
      if (data.isSelected) {
        if (index === -1) {
          this.selectedItems.push(data.item)
        }
      } else {
        if (index >= 0) {
          this.selectedItems.splice(index, 1)
        }
      }
    },
    handleAndEmitSelected (data) {
      this.handleSelected(data)
      this.$emit('input', cleanClone(this.localValue))
    },
    formatSelected (selectedItem) {
      return selectedItem
    },
    transformValue (valueItem) {
      return valueItem
    },
    findSelectedItem: item => data => item.properties.id === data.properties.id,
    findValueItem: item => data => item.properties.id === data.properties.id
  }
}
