<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="model = $event"
      :items="getItems"
      :item-value="itemValue"
      :item-text="itemText"
      :loading="loading"
      :search-input.sync="search"
      :cache-items="false"
      :label="label"
      :disabled="disabled"
      :append-icon="appendIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      return-object
      :clearable="clearable"
      :outlined="outlined"
      :dense="dense"
      :color="color"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      placeholder="Начните вводить данные для поиска"
    >
      <template v-slot:append-item>
        <slot
          name="appendItem"
        ></slot>
      </template>
    </v-autocomplete>
    <v-messages
      class="pl-3"
      color="error"
      :value="errorMessages"
    ></v-messages>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import _ from 'lodash'
import BaseHint from '@/components/base/UI/BaseHint'

export default {
  mixins: [formatters],
  name: 'BaseAutocomplete',
  components: { BaseHint },
  props: {
    value: [Object, String],
    appendIcon: String,
    prependIcon: String,
    prependInnerIcon: String,
    itemText: String,
    itemValue: String,
    url: String,
    charsToSearch: {
      type: Number,
      default: 5
    },
    clearable: Boolean,
    outlined: Boolean,
    dense: Boolean,
    label: String,
    color: String,
    hideDetails: Boolean,
    errorMessages: Array,
    disabled: Boolean,
    params: Object
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null,
      model: '',
      autocompleteString: ''
    }
  },
  watch: {
    search: {
      async handler () {
        const clearSearch = this.search
        if (clearSearch === '' || clearSearch === null) {
          this.model = ''
          this.items = []
          this.autocompleteString = ''
        } else if (!this.loading && clearSearch.length >= this.charsToSearch && (!this.model || this.search !== this.model[this.itemText])) {
          this.autocompleteString = clearSearch
        } else {
          this.autocompleteString = ''
        }

        await this.debounceAutocomplete()
        return true
      }
    },
    model: {
      handler: function () {
        this.$emit('input', this.model)
      },
      deep: true
    },
    value: {
      handler (val) {
        if (val && val.id) {
          this.items.push(this.value)
          this.model = this.value
          this.search = this.model[this.itemText]
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    getItems () {
      return this.items
    }
  },
  methods: {
    debounceAutocomplete: _.debounce(async function () {
      return this.autocomplete()
    }, 400),
    async autocomplete () {
      if (this.autocompleteString) {
        this.loading = true
        const response = await this.$store.dispatch('server/autocomplete', {
          url: this.url,
          params: { filter: { ...this.params, q: this.autocompleteString } }
        })
        if (response && response.count) {
          this.items = this.processItems(response.models)
        }
        this.loading = false
      }
      return true
    },
    processItems (items) {
      return items
    }
  }
}
</script>

<style scoped>

</style>
