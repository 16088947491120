<template>
  <div v-if="info">
    <div
      class="mb-5"
      v-if="info.status === 'parse'"
    >
      <v-progress-linear
        v-model="info.parsedCount/info.totalCount*100"
        rounded
        height="12"
        color="green"
      >
      </v-progress-linear>
      <div class="d-flex">
        <div class="mr-2">
          <span
            v-if="info.totalCount"
          >Всего объектов: {{ info.totalCount }}</span></div>
        <div class="mr-2">
          <span
            v-if="info.parsedCount"
          >Импортировалось всего: {{ info.parsedCount }}</span></div>
      </div>
    </div>
    <v-alert
      v-else
      :type="info.status"
    >
      <div class="title">{{ info.status === 'error' ? 'Ошибка' : '' }}</div>
      <div class="d-flex">
        <div>
          <span class="mr-2">Начало импорта: {{ timestampToDatetime(info.beginAt) }}</span><br>
          <span class="mr-2">Окончание импорта: {{ timestampToDatetime(info.finishAt) }}</span><br>
          <span
            v-if="info.totalCount"
            class="mr-2"
          >Всего объектов: {{ info.totalCount }}</span>
        </div>
        <div class="ml-5">
          <span
            v-if="info.parsedCount"
            class="mr-2"
          >Импортировалось всего: {{ info.parsedCount }}</span><br>
          <span
            v-if="info.errorCount"
            class="mr-2"
          >Импортировалось с ошибками: {{ info.errorCount }}</span><br>
          <span
            v-if="info.error"
            class="mr-2"
          >Ошибки: {{ info.error }}</span>
        </div>
      </div>
    </v-alert>
    <v-card
      v-if="info.items.length"
      outlined
    >
      <v-card-title>Импортировалось с ошибками</v-card-title>
      <v-card-text>
        <v-expansion-panels
          flat
          accordion
        >
          <v-expansion-panel
            class="panel-element"
            v-for="item in info.items"
            :key="item.id"
          >
            <v-expansion-panel-header>
              <div class="subtitle-2">Объект {{ item.sourceObject ? item.sourceObject.id : '' }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(subitem, index) in item.errorText.split('\n')"
                :key="index"
              >
                {{ subitem }}
              </div>
              <div v-if="item.sourceObject">
                <div class="mt-4">
                  <a
                    style="border-bottom: 1px dashed"
                    @click="show=!show"
                  >
                    Объект целиком
                  </a>
                </div>
                <div
                  class="mt-2"
                  v-show="show"
                >
                  {{ item.sourceObject }}
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'BaseParserDetails',
  props: {
    urlCheck: {
      type: String,
      default: 'process/status'
    },
    eavId: Number,
    passId: Number,
    parentId: Number,
    entityId: Number,
    categoryId: Number,
    objectType: String,
    params: Object
  },
  data () {
    return {
      show: false,
      info: null
    }
  },
  computed: {
    eavEntityId () {
      let result = null
      if (this.eavId) {
        result = this.eavId
      } else if (this.$route.params.entityId) {
        result = this.$route.params.entityId
      }
      return result
    },
    passportId () {
      let result = null
      if (this.passId) {
        result = this.passId
      } else if (this.$route.params.id) {
        result = this.$route.params.id
      }
      return result
    }
  },
  methods: {
    async check () {
      if (this.urlCheck === 'process/status') {
        const response = await this.$store.dispatch('api/post', {
          url: this.urlCheck,
          data: this.params
        })
        if (response.success && response.data) {
          if (response.data.status === 'parse' && this.params.params.id) {
            setTimeout(async () => this.check(), 5000)
          } else {
            this.$emit('update:params', {
              ...this.params,
              params: { // чтобы избавиться от всей этой Сатаны предлагаю сделать один единственный "entityId" для всех
                passportId: this.passportId,
                eavEntityId: this.eavEntityId,
                parentId: this.parentId,
                objectType: this.objectType,
                entityId: this.entityId,
                categoryId: this.categoryId,
              }
            })
            this.$emit('parse:completed')
          }
          this.info = response.data
        }
        return true
      } else {
        const response = await this.$store.dispatch('api/get', {
          url: this.urlCheck,
          params: this.params
        })
        if (response.success) {
          if (response.data && response.data.status === 'parse') {
            setTimeout(async () => this.check(), 10000)
          } else {
            if (this.params.logId) {
              this.$emit('parse:completed')
            }
          }
          this.info = response.data
        }
        return true
      }
    },
    async start () {
      const response = await this.$store.dispatch('api/post', {
        url: 'process/start',
        data: this.params
      })
      if (response.data?.id) {
        this.$emit('update:params', {
          ...this.params,
          params: {
            id: response.data.id
          }
        })
        this.$nextTick(() => {
          if (this.params.params.id) {
            this.check()
          }
        })
      }
      return true
    }
  }
}
</script>

<style scoped>
.panel-element {
  border: 1px solid rgba(203, 203, 203, 0.49);
}
.panel-element:not(:last-child) {
  border-bottom: none;
}
</style>
