<template>
  <v-dialog
    persistent
    :value="value"
    @input="$emit('input', $event)"
    transition="dialog-bottom-transition"
    :max-width="maxWidth"
    :content-class="getClass"
    @click:outside="!isCrossClose && handleClose"
    :fullscreen="isFullScreen"
    :overlay-opacity="overlayOpacity"
    :retain-focus="retainFocus"
  >
    <v-card
      :color="isTransparent ? 'transparent' : ''"
      flat
      :class="cardClass ? cardClass : ''"
    >
      <v-card-title
        v-if="withLabel"
        class="d-flex justify-lg-space-between fixed-bar"
        style="border: none"
        :class="classCardTitle"
      >
        <v-icon
          class="mr-1"
          v-if="prependIconLabel"
        >{{ prependIconLabel }}
        </v-icon>
        <slot name="prependName"/>
        <div class="text-heading-4 font-weight-bold text-break">
          <div
            v-if="littleLabel"
            class="text-subtitle-1 font-weight-bold"
          >{{ labelName }}
          </div>
          <div v-else>{{ labelName }}</div>
          <v-card-subtitle
            v-if="subtitle"
            class="pa-0 ma-0"
          > {{ subtitle }}
          </v-card-subtitle>
          <slot name="subtitle"></slot>
        </div>
        <v-icon v-if="appendIconLabel">{{ appendIconLabel }}</v-icon>
        <v-spacer></v-spacer>
        <slot name="customBtn"></slot>
      </v-card-title>
      <BaseBtn
        v-if="isCrossClose"
        is-type-icon
        icon="mdi-close"
        small
        style="position: absolute; top: 10px; right: 10px; z-index: 2"
        class="align-self-baseline"
        @click="handleClose"
        :color="isTransparent ? 'white' : ''"
      />
      <v-card-text
        class="text-body-1"
        v-if="text"
      >
        {{ text }}
      </v-card-text>
      <div class="wrapper-content" :class="classContent">
        <slot name="content"></slot>
      </div>
      <v-card-actions class="justify-end pb-4">
        <BaseBtn
          color="primary"
          @click="handleConfirm"
          depressed
          :title="titleConfirm"
          class="mr-2"
          class-btn="text-capitalize"
          v-if="isConfirmBtn"
          :loading="isLoading"
          :disabled="isDisabledConfirm"
        />
        <BaseBtn
          depressed
          @click="handleClose"
          class-btn="grey lighten-3 text-capitalize primary--text"
          :title="titleCancel"
          v-if="isCancelBtn"
          :loading="isLoading"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import keyup from '@/components/mixins/keyup'

// ПРИМЕЧАНИЕ: для окна удаления указать role: 'delete' и itemId
export default {
  name: 'BaseDialog',
  components: { BaseBtn },
  mixins: [keyup],
  props: {
    value: Boolean,
    retainFocus: Boolean,
    label: String,
    text: String,
    overlayOpacity: String,
    isTransparent: Boolean,
    subtitle: String,
    maxWidth: {
      type: [Number, String],
      default: 1000
    },
    hideScroll: Boolean,
    isConfirmBtn: Boolean,
    isCancelBtn: Boolean,
    textConfirm: { default: 'Да', type: String },
    textCancel: { default: 'Нет', type: String },
    withLabel: {
      default: true,
      type: Boolean
    },
    isCrossClose: Boolean,
    isFullScreen: Boolean,
    appendIconLabel: String,
    prependIconLabel: String,
    isLoading: Boolean,
    role: {
      default: 'base',
      type: String
    },
    itemId: Number,
    isDisabledConfirm: Boolean,
    withoutSidesPadding: Boolean, // без боковых паддингов
    littleLabel: Boolean, // делает лейб меньше
    withoutBotPadding: Boolean, // убирает нижний паддинг у card-title
    isNestedDialog: Boolean, // для вложенных диалоговых окон, чтобы отключить close на esc. Иначе оба закроются
    cardClass: String // передаем название класса, который создаем в этом же компоненте (лучше чет не придумал)
  },
  computed: {
    getClass () {
      let classStyles = ''
      if (this.hideScroll) {
        classStyles += 'hideScroll '
      }
      if (this.isFullScreen) {
        classStyles += 'hideBorder '
      }
      return classStyles
    },
    /* labelName () {
       return !this.label && this.role !== 'delete' ?
         this.$t('base.import') :
         this.label ?
           this.label :
           this.$t('base.attention')
     }, note: переписал его ниже, от хранения тут названия диалога "Импорт" пользы нет, и название было странное
        todo: удалить, если ничего не сломалось*/
    labelName () {
      return this.label ? this.label : (this.role === 'delete' ? this.$t('base.attention') : '')
    },
    titleConfirm () {
      return this.role === 'base' ? this.textConfirm : this.$t('base.delete')
    },
    titleCancel () {
      return this.role === 'base' ? this.textCancel : this.$t('base.cancel')
    },
    classContent () {
      let result = !this.withLabel ? 'pt-5 ' : ''
      if (!this.withoutSidesPadding) {
        result = 'pr-5 pl-5'
      }
      return result
    },
    classCardTitle () {
      let result = this.isTransparent ? 'transparent ' : ''
      if (this.withoutSidesPadding) {
        result = 'pl-4'
      }
      if (this.withoutBotPadding) {
        result += ' pb-0'
      }
      return result
    }
  },
  methods: {
    handleClose () {
      if (!this.isNestedDialog) {
        this.$emit('input', false)
      }
    },
    handleConfirm () {
      if (!this.isDisabledConfirm) {
        if (this.role === 'delete') {
          this.$emit('confirm', this.itemId)
          this.$emit('input', false)
        } else {
          this.$emit('confirm')
        }
      }
    }
  }
}
</script>

<style scoped>
  /deep/ .fixed-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  /deep/ .hideScroll {
    overflow-y: hidden !important;
  }
  /deep/ .hideBorder {
    border-radius: 0 !important;
  }
  /*название класс .object-panel-card передается пропсом*/
  .object-panel-card {
    border-radius: 9px;
    background: linear-gradient(170deg, #FFF 6.92%, #FFF8FF 32.76%, #DEF7FF 90.07%);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10);
  }
  .wrapper-content {
    height: 100%;
  }
</style>
