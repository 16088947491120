<template>
  <div
    class="base-header"
    :class="subtitle ? 'height-header' : ''"
  >
    <div class="d-flex flex-column justify-start align-start">
      <div class="title-base-header">{{ title || $route.meta.title }}</div>
      <v-card-subtitle
        v-if="subtitle"
        class="pa-0 ma-0 mb-1"
      >{{ subtitle }}
      </v-card-subtitle>
    </div>
    <div class="wrapper-base-header">
      <slot name="customButtons"></slot>
      <BaseBtn
        v-if="confirmBtn"
        color="secondary"
        icon="mdi-check"
        x-small
        :title="$t('base.confirm')"
        @click="$emit('confirm')"
      />
      <BaseBtn
        v-if="reportBtn"
        icon="mdi-text-box-multiple"
        x-small
        :title="$t('base.createReport')"
        @click="$emit('report')"
      />
      <BaseBtn
        v-if="saveBtn"
        color="primary"
        icon="mdi-content-save"
        x-small
        :title="$t('base.save')"
        @click="$emit('save')"
      />
      <BaseBtn
        v-if="restoreBtn"
        color="secondary"
        x-small
        icon="mdi-restore"
        :title="$t('base.restore')"
        @click="$emit('restore')"
      />
      <BaseBtn
        v-if="deleteBtn"
        color="primary"
        icon="mdi-delete"
        x-small
        :title="$t('base.delete')"
        @click="deleteDialog = !deleteDialog"
      />
      <BaseBtn
        v-if="deleteDataBaseBtn"
        color="error"
        icon="mdi-delete"
        x-small
        :title="$t('base.dataBaseDelete')"
        @click="$emit('dataBaseDelete')"
      />
      <BaseBtn
        v-if="rejectBtn"
        color="error"
        icon="mdi-cancel"
        x-small
        :title="$t('base.cancel')"
        @click="$emit('reject')"
      />
      <BaseBtn
        v-if="closeBtn"
        color="primary"
        icon="mdi-close"
        x-small
        :title="$t('base.close')"
        @click="$emit('close')"
      />
      <BaseBtn
        v-if="importBtn"
        icon="mdi-swap-vertical"
        x-small
        :title="$t('base.import')"
        @click="$emit('import')"
      />
    </div>
    <BaseDialog
      :text="textDelete || $t('message.areYouSure')"
      @confirm="$emit('delete')"
      v-model="deleteDialog"
      is-cancel-btn
      is-confirm-btn
      :max-width="350"
      role="delete"
    />
  </div>
</template>

<script>
import BaseBtn from '../UI/BaseBtn'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'BaseHeader',
  components: { BaseDialog, BaseBtn },
  props: {
    saveBtn: { type: Boolean, default: true },
    closeBtn: { type: Boolean, default: true },
    deleteBtn: { type: Boolean, default: true },
    confirmBtn: { type: Boolean, default: false },
    rejectBtn: { type: Boolean, default: false },
    deleteDataBaseBtn: { type: Boolean, default: false },
    restoreBtn: { type: Boolean, default: false },
    importBtn: { type: Boolean, default: false },
    reportBtn: { type: Boolean, default: false },
    textDelete: String,
    title: String,
    subtitle: String
  },
  data () {
    return {
      deleteDialog: false
    }
  }
}
</script>

<style scoped>
  .height-header {
    height: 45px;
  }
</style>
