import * as turf from '@turf/turf'

export function emitEvent (event, data) {
  document.dispatchEvent(new CustomEvent(event, { detail: data }))
}

export function areaPolygon ({ coordinates, type }) {
  const isMultiPolygon = type === 'MultiPolygon'
  let area = 0
  if (!isMultiPolygon) { //для обычного полигона
    const polygon = turf.polygon(coordinates)
    area = turf.area(polygon)
  } else if (isMultiPolygon) {
    const multiPoly = turf.multiPolygon(coordinates)
    area = turf.area(multiPoly)
  }
  return area / 1000 // перевожу в км
}